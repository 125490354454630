import React from "react";
import "./services.css";
import "./about.css";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import "./services.css";
import "./tech.css";
import StripesCyan from "../Assets/Main/Hero/StripesCyan.png";
import group from "../Assets/About/group.jpg";
import tianyi from "../Assets/About/tianyi.jpg";
import transparency from "../Assets/About/transparency.svg";
import flexibility from "../Assets/About/flexibility.svg";
import check from "../Assets/About/check.svg";
import shield from "../Assets/About/shield.svg";
import mission from "../Assets/Careers/mission.svg";
import unnamed from "../Assets/Images/unnamed.png";
import unamed3 from "../Assets/Images/unamed3.png";

function About() {
  return (
    <div>
      {/* <Row className="justify-content-center" className="about-row">
        <Col md={12} lg={12} xs={12}>
          {" "}
          <div>
            <span className="about-heading">
              Now,here are few things <br />
              that you must be aware of us{" "}
            </span>
            <img src={StripesCyan} className="service-img" />
            <Row className="justify-content-center">
              <Col md={12} lg={8} xs={11} className="abouthead-text">
                <p>
                  {" "}
                  We are a small team working to bring out the best for our
                  clients and help you grow professionally and personally.
                </p>
              </Col>
            </Row>
          </div>{" "}
        </Col>
      </Row> */}
      <div style={{ marginBottom: "159px" }}>
        <Row style={{ marginTop: "160px" }} className="justify-content-center">
          <Col md={12} lg={12} xs={12}>
            <h1 className="heading">
              Now,here are few things <br />
              that you must be aware of us{" "}
            </h1>
          </Col>
          <div className="overlay">
            <img
              height="180px"
              width="180px"
              style={{ opacity: "0.6" }}
              src={StripesCyan}
            />
          </div>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              {" "}
              We are a small team working to bring out the best for our clients
              and help you grow professionally and personally.
            </p>
          </Col>
        </Row>
      </div>

      <Container>
        <Row
          className="justify-content-between"
          style={{ marginBottom: "200px" }}
        >
          {/* <Col lg={7} md={6} xs={8}>
            <img src={group} className="about-image" />
          </Col>
          <Col lg={5} md={6} xs={12}>
            <h2 style={{ textAlign: "center" }}>Our Story</h2>
            <p className="about-text">
              {" "}
              At Texerd,we are team of young, dynamic and vibrant people coming
              from technical and creative fields and work with dedication to
              digitally create new or modify existing business processes,culture
              and customer experiences to meet changing business and market
              requirements.We believe,what we design,create and produce will
              help you reap umpteen profits.
            </p>
          </Col> */}

          <Col lg={6} xs={8} md={6}>
            <img src={group} className="newimage" />
          </Col>

          <Col lg={6} xs={12} md={6}>
            <h2 style={{ textAlign: "center" }}>Our Story</h2>
            <p className="about-text">
              {" "}
              At Texerd,we are team of young, dynamic and vibrant people coming
              from technical and creative fields and work with dedication to
              digitally create new or modify existing business processes,culture
              and customer experiences to meet changing business and market
              requirements.We believe,what we design,create and produce will
              help you reap umpteen profits.
            </p>
          </Col>
        </Row>
        <Row
          className="justify-content-center"
          style={{ marginBottom: "200px", marginTop: "100px" }}
        >
          <Col lg={10} md={12} xs={12}>
            <h2 className="about-head">Our Mission</h2>
            <p className="about-text1">
              {" "}
              <img src={unnamed} style={{ width: "60px" }} />
              We blend technology and creativity to provide impeccable solutions
              to all kind of business problems.{" "}
              <img src={unamed3} style={{ width: "40px" }} />
            </p>
          </Col>
        </Row>
        <Row
          className="justify-content-center"
          style={{ marginBottom: "200px", marginTop: "100px" }}
        >
          <Col lg={10} md={12} xs={12}>
            <h2 className="about-head">Our Core Values</h2>
            <Row
              className="justify-content-between"
              style={{ marginTop: "50px" }}
            >
              <Col lg={2} md={2} xs={6}>
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={transparency}
                    style={{
                      width: "30px",
                      height: "50px",
                      marginLeft: "8px",
                    }}
                  />
                  <p style={{ fontWeight: 700, fontSize: "20px" }}>
                    Transperency
                  </p>
                </div>
              </Col>
              <Col lg={2} md={2} xs={6}>
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={check}
                    style={{
                      width: "30px",
                      height: "50px",
                      marginLeft: "8px",
                    }}
                  />
                </div>
                <p style={{ fontWeight: 700, fontSize: "20px" }}>
                  Accountability
                </p>
              </Col>
              <Col lg={2} md={2} xs={6}>
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={flexibility}
                    style={{
                      width: "30px",
                      height: "50px",
                      marginLeft: "8px",
                    }}
                  />
                </div>
                <p style={{ fontWeight: 700, fontSize: "20px" }}>
                  Transperency
                </p>
              </Col>
              <Col lg={2} md={2} xs={6}>
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={shield}
                    style={{
                      width: "30px",
                      height: "50px",
                      marginLeft: "8px",
                    }}
                  />
                </div>
                <p style={{ fontWeight: 700, fontSize: "20px" }}>Creativity</p>
              </Col>
              <Col lg={2} md={2} xs={6}>
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={mission}
                    style={{
                      width: "30px",
                      height: "50px",
                      marginLeft: "8px",
                    }}
                  />
                </div>
                <p style={{ fontWeight: 700, fontSize: "20px" }}>Quality</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          className="justify-content-center"
          style={{ marginBottom: "200px" }}
        >
          <Col lg={10} md={12} xs={12}>
            <h2 className="about-head">Our Vision</h2>
            <p className="about-text1">
              {" "}
              <img src={unnamed} style={{ width: "60px" }} />
              Our vision is to be referred as a most dedicated,
              innovative,passionate and an amiable team anyone is looking to
              work with or work for .{" "}
              <img src={unamed3} style={{ width: "40px" }} />
            </p>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col lg={12} md={12} xs={12}>
          <h1 className="overlap">
            We appreciate the
            <br /> curious and creative
          </h1>{" "}
          <img
            style={{ width: "100%", height: 400 }}
            src={tianyi}
            className="about-last-img"
          />
        </Col>
      </Row>
    </div>
  );
}

export default About;
