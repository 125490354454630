import React from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";

function Workcard(props) {
  return (
    <div>
      <Row className="justify-content-center">
        {" "}
        <Card style={{ marginTop: "30px" }}>
          <img src={props.image1} style={{ height: 200 }} />
          <img src={props.image2} className="work-image" />
          <Row className="justify-content-center">
            {" "}
            <h2
              style={{
                display: "flex",
                textDecoration: "none",
                justifyContent: "center",
                position: "relative",
                top: "-50px",
                fontFamily: "revert",
                fontWeight: "700",
                color: "black",
              }}
            >
              {props.title}
            </h2>
            <Col lg={10} md={10} xs={10}>
              {" "}
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  top: "-50px",
                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                {props.text}{" "}
              </p>
            </Col>
          </Row>
          <Row className="justfiy-content-center">
            <Col lg={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  position: "relative",
                  top: "-50px",
                  fontFamily: "fantasy",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                <Button variant="outline-primary">{props.button1}</Button>{" "}
                <Button variant="outline-warning">{props.button2}</Button>{" "}
                <Button variant="outline-danger">{props.button3}</Button>{" "}
              </div>
            </Col>
          </Row>
        </Card>
      </Row>
    </div>
  );
}

export default Workcard;
