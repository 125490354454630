import React from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import womanvr from "../Assets/Services/UserResearch/womanvr.jpg";
import service1 from "../Assets/Services/UserResearch/service1.png";
import service3 from "../Assets/Services/UserResearch/service3.png";
import sernice2 from "../Assets/Services/UserResearch/sernice2.png";
import "../pages/services.css";

function Servicecard(props) {
  return (
    <div>
      <Row className="justify-content-center">
        <Col md={12} lg={10} xs={12} xl={12}>
          <img src={props.img} className="service-woman" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={9} xs={11} md={10} xl={11}>
          <Card
            className="shadow-lg"
            style={{
              marginTop: -180,
              justifyContent: "center",
              marginBottom: 100,
              textAlign: "left",
              paddingLeft: 40,
              paddingRight: 40,
              borderRadius: 1,
              fontFamily: "serif",
              fontSize: 18,
              fontWeight: 500,
              color: "grey",
            }}
          >
            <Row style={{ marginTop: 30 }} className="justify-content-between">
              <Col md={6} lg={4} xs={12}>
                <h2 style={{ fontWeight: "700", color: "black" }}>
                  {props.title}
                </h2>
                <p className="service-bottom-text"> {props.subcontext}</p>
              </Col>
              <Col md={3} lg={4} xs={6} className="service-bottom-text">
                <p>{props.content}</p>
                <p>{props.content1}</p>
                <p>{props.content2}</p>

                <Row className="justify-content-between">
                  <Col md={6} lg={3} xs={6}>
                    <img
                      src={props.img2}
                      style={{ width: "40px", marginTop: "10px" }}
                    />
                  </Col>
                  <Col md={6} lg={3} xs={6}>
                    <img
                      src={props.img4}
                      style={{ width: "40px", marginTop: "10px" }}
                    />
                  </Col>
                  <Col md={6} lg={3} xs={6}>
                    <img
                      src={props.img3}
                      style={{ width: "40px", marginTop: "10px" }}
                    />
                  </Col>
                  <Col md={6} lg={3} xs={6}>
                    <img
                      src={props.img1}
                      style={{
                        width: "40px",
                        marginTop: "10px",
                        marginBottom: "90px",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={3} lg={4} xs={6} className="service-bottom-text">
                {" "}
                <p>{props.content3}</p>
                <p>{props.content4}</p>
                <p>{props.content5}</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Servicecard;
