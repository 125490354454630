import React from "react";
import "./home.css";
import { Card, CardTitle, CardText } from "react-bootstrap";
import StripsCircled from "../Assets/Main/Hero/StripsCircled.svg";
import Stripe_BG from "../Assets/Main/Hero/Stripe_BG.svg";
import StripePBG from "../Assets/Main/Hero/StripePBG.svg";
import arrow from "../Assets/Main/Hero/arrow.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import avatarman from "../Assets/Images/avatarman.png";
import avatar from "../Assets/Images/avatar.png";

import design from "../Assets/Services/Design/design.svg";
import responsive from "../Assets/Start/responsive.svg";
import dashboard from "../Assets/Start/dashboard.svg";
import { FaBeer } from "react-icons/fa";
import swarm from "../Assets/Main/Clients/swarm.svg";
import tick from "../Assets/Main/Why Choose/tick.svg";
import download1 from "../Assets/Images/download1.png";
import bargraph1 from "../Assets/Main/Hero/bargraph1.png";
import emailjs from "emailjs-com";

import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import Footer from "../components/Footer";
import "./project.css";
import Valerie from "../Assets/Images/Valerie.jpg";
import woman from "../Assets/Images/woman.jpg";
import young from "../Assets/Images/young.jpg";
import { toast, ToastContainer } from "react-toastify";

function Home() {
  function enquiry() {
    var full_name = document.getElementById("full_name").value;
    var full_email = document.getElementById("full_email").value;
    var message_details = document.getElementById("message").value;
    if (full_name === "") {
      toast.error("Please enter your name", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
      });
      document.getElementById("full_name").focus();
      return false;
    }
    if (full_email === "") {
      toast.error("Please enter your email", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
      });
      document.getElementById("full_email").focus();
      return false;
    }
    if (message_details === "") {
      toast.error("Please enter your Message", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
      });
      document.getElementById("message").focus();
      return false;
    }
    emailjs
      .send(
        "service_ksq7nwj",
        "template_26t1dzn",
        {
          to_email: "saberaaziz13@gmail.com",
          message: message_details,
          from_name: full_name,
          from_email: full_email,
          // assistance_details: "Not clear",
        },
        "user_kP3UPA45xfB7QsBuM8k8x"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          toast.success(" Message Sent Successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
          });
        },
        (err) => {
          console.log("FAILED...", err);
          toast.error(" An Error Occured", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
          });
        }
      );
  }
  return (
    <div>
      <ToastContainer
        position="top-center"
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
      <Row className="justify-content-center">
        <Col md={2} lg={4} xs={8} xl={4}>
          <img src={StripsCircled} className="image-head" />
        </Col>
        <Col md={10} lg={6} xs={11} xl={6}>
          <span className="home-heading2">
            Creative Digital <br /> Business solutions.{" "}
          </span>
          <p className="home-subtext">
            Let us be a part in taking your business to the next level. We blend
            technology and creativity to provide impeccable solutions to all
            kind of business problems.
          </p>
          <a href="#contactus">
            {" "}
            <Button className="Button">
              Start a Project &nbsp;
              <img
                src={arrow}
                style={{ width: 20, height: 20, marginTop: 2 }}
              />
            </Button>
          </a>
        </Col>
        <Col md={5} lg={2} xs={12}>
          <img src={Stripe_BG} class="image-2" />
        </Col>
      </Row>
      <Container>
        <Row
          className="justify-content-center"
          style={{ marginTop: 190, marginBottom: 110 }}
        >
          <Col md={12} lg={5} xs={12}>
            <h5 className="heading1">Our Services</h5>
            <h2 className="heading2">Grow your business With us.</h2>
            <p
              style={{
                textAlign: "left",
                marginTop: 9,
                fontSize: 20,
                fontWeight: "600",
                color: "grey",
              }}
            >
              Looking forward to take a critical step in improving the way that
              you engage and communicate with your audience, by integrating all
              web and mobile channels to ensure consistency across customer
              touch points
            </p>
            <Link to="/services">
              {" "}
              <Button
                style={{
                  justifyContent: "flex-start",
                  display: "flex",
                  borderRadius: 1,
                  marginBottom: 10,
                }}
              >
                All services
              </Button>
            </Link>
          </Col>
          <Col md={12} lg={6} xs={12}>
            <Row>
              <Col md={12} lg={6} xs={12} className="home-card">
                <Card border="light" className="home-card1">
                  <Row className="justify-content-center">
                    <Col md={12} lg={10} xs={12}>
                      <img
                        src={design}
                        style={{
                          width: 50,
                          height: 60,
                        }}
                      />

                      <h2 className="card-heading">Web Design</h2>
                      <Row className="justify-content-center">
                        <Col md={12} lg={12} xs={12}>
                          <p
                            className="home-card"
                            style={{
                              fontSize: 16,
                              marginBottom: 10,
                              alignText: "center",
                            }}
                          >
                            Design opens up valuable opportunities for
                            businesses and bring some significant business
                            benefits.The research and prototype stages of the
                            design process.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={12} lg={6} xs={12}>
                <Card border="light" className="home-card1">
                  <Row className="justify-content-center">
                    <Col md={12} lg={10} xs={12}>
                      <div className="home-card">
                        <img
                          src={responsive}
                          style={{ width: 50, height: 60 }}
                        />
                      </div>
                      <h2 className="card-heading">Development</h2>
                      <Row className="justify-content-center">
                        <Col md={12} lg={12} xs={12}>
                          <p
                            className="home-card"
                            style={{
                              fontSize: 16,
                              marginBottom: 10,
                              alignText: "center",
                            }}
                          >
                            Increase your visibility and brand recognition with
                            our customised mobile applications that will keep
                            your audience engaged anywhere and anytime.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>{" "}
              <Col md={12} lg={6} xs={12}>
                <Card border="light" className="home-card1">
                  <Row className="justify-content-center">
                    <Col md={12} lg={10} xs={12}>
                      <div className="home-card">
                        <img
                          src={dashboard}
                          style={{ width: 50, height: 60 }}
                        />
                      </div>
                      <h2 className="card-heading">UI Design</h2>
                      <Row className="justify-content-center">
                        <Col md={12} lg={12} xs={12}>
                          <p
                            className="home-card"
                            style={{
                              fontSize: 16,
                              marginBottom: 6,
                              alignText: "center",
                            }}
                          >
                            UX (user experience) research is the systematic
                            study of target users and their requirements,to add
                            realistic contexts to design processes. UX
                            researchers adopt various methods to uncover
                            opportunities.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>{" "}
              <Col md={12} lg={6} xs={12}>
                {" "}
                <Card border="light" className="home-card1">
                  <Row className="justify-content-center">
                    <Col md={12} lg={10} xs={12}>
                      <div className="home-card">
                        <img
                          src={download1}
                          style={{ width: 50, height: 60 }}
                        />
                      </div>
                      <h2 className="card-heading">Visualization</h2>
                      <Row className="justify-content-center">
                        <Col md={12} lg={12} xs={12}>
                          <p
                            className="home-card"
                            style={{
                              fontSize: 16,
                              marginBottom: 6,
                              alignText: "center",
                            }}
                          >
                            A visual summary of information makes it easier to
                            identify patterns and trends than looking through
                            thousands of rows on a spreadsheet.Charts and graphs
                            make communicating data findings easier.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>{" "}
              </Col>
            </Row>{" "}
          </Col>
        </Row>

        <Row
          className="justify-content-center"
          style={{ marginBottom: "10px", marginTop: "80px" }}
        >
          <Col md={6} lg={12} xs={12}>
            <h1 style={{ textAlign: "center", fontWeight: "700" }}>
              Testimonals
            </h1>
          </Col>
        </Row>
        <Row
          className="justify-content-center"
          style={{ marginBottom: "100px" }}
        >
          <Col md={12} lg={12} xs={12}>
            <Carousel
              style={{
                color: "black",
              }}
              indicators={false}
              controls={false}
            >
              <Carousel.Item>
                <Row style={{ marginBottom: "75px", justifyContent: "center" }}>
                  <Col lg={10}>
                    <Card className="shadow" style={{ marginBottom: "70px" }}>
                      <Row className="justify-space-around">
                        <Col lg={3} md={4} xs={12} className="project-test">
                          <img src={avatarman} className="project-img" />
                          <br />
                          <h3 className="headingthree">-Managing Director</h3>
                          <p className="project-text">SKP group</p>
                        </Col>
                        <Col lg={8} xs={12} md={6} className="project-eleven">
                          "Texerd is a great company to work with as they are
                          always responsive and willing to do what it takes to
                          get the job done right and in a timely manner. They
                          are very reliable and intelligent. It is clear right
                          away that Texerd was the right choice to revamp our
                          website, logo, and other items. Thank you Texerd"
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row style={{ marginBottom: "75px", justifyContent: "center" }}>
                  <Col lg={10}>
                    <Card className="shadow" style={{ marginBottom: "70px" }}>
                      <Row className="justify-space-around">
                        <Col lg={3} md={4} xs={12} className="project-test">
                          <img src={avatar} className="project-img" />
                          <br />
                          <h3 className="headingthree">-Owner </h3>
                          <p className="project-text">
                            Direct Sarees From Veawers
                          </p>
                        </Col>
                        <Col lg={8} xs={12} md={6} className="project-eleven">
                          I approached Texerd for an e-commerce mobile
                          application for our family business . They clearly
                          understood the gap between the dealers and customers
                          and built an extraordinary app to bridge that gap .
                          This has helped me boost my sales and made my work
                          easy. Thank you Texerd for being there and addressing
                          all my concerns.
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row id="contactus">
          <Col md={12} lg={12} xs={12}>
            <Card
              className="work-card"
              style={{
                backgroundColor: "#DBDBDD",
                borderColor: "#f5f5f5",
                marginTop: 100,
                paddingLeft: 30,
              }}
            >
              {" "}
              <Row>
                <Col md={6} lg={6}></Col>
                <Col md={6} lg={6}>
                  <h5
                    className="heading1"
                    style={{
                      textAlign: "right",
                      marginRight: 20,
                      marginTop: 10,
                    }}
                  >
                    Contact us
                  </h5>
                  <h2
                    className="heading2"
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      color: "black",
                      marginRight: "14px",
                      marginTop: 20,
                    }}
                  >
                    Lets Collab
                  </h2>
                  <div
                    className="home-card"
                    style={{
                      fontSize: 16,
                      marginBottom: 6,
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "black",
                      marginRight: "10px",
                      fontSize: 22,
                      marginBottom: 100,
                    }}
                  >
                    Drop an email and we will get back to you.
                  </div>
                </Col>{" "}
              </Row>{" "}
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center" id="contactus">
          <Col lg={10} xs={11} md={10}>
            <Card
              className="shadow-lg"
              style={{
                marginTop: -80,
                justifyContent: "center",
                marginBottom: 100,
                textAlign: "left",
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 1,
                fontFamily: "serif",
                fontSize: 18,
                fontWeight: 500,
                color: "grey",
              }}
            >
              <Row style={{ marginBottom: 40 }}>
                <Col lg={8} md={8} xs={12}>
                  <p style={{ marginTop: 40, marginBottom: 30 }}>
                    First,Provide us with your details.This helps us get in
                    touch with us
                  </p>
                  <Row>
                    <Col lg={5}>
                      <p style={{ color: "black" }}>What's your name?</p>
                      <Form.Group>
                        <Col xs={10}>
                          <Form.Control
                            size="md"
                            type="text"
                            id="full_name"
                            placeholder="Full Name"
                            style={{ borderRadius: 2 }}
                          />
                          <br />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col lg={5}>
                      <p style={{ color: "black" }}>
                        What's your email address?
                      </p>
                      <Form.Group>
                        <Col xs={10}>
                          {" "}
                          <Form.Control
                            size="md"
                            type="text"
                            id="full_email"
                            placeholder="abc@gmail.com"
                            style={{ borderRadius: 2 }}
                          />
                          <br />
                        </Col>
                      </Form.Group>
                    </Col>
                    <p style={{ marginLeft: 10 }}>
                      What kind of assistance do you need? Select all that are
                      applicable
                    </p>
                    <Row>
                      <Col
                        lg={7}
                        xs={12}
                        style={{ margin: "10px", color: "black" }}
                      >
                        <InputGroup>
                          <InputGroup.Checkbox aria-label="Radio button for following text input" />
                          <span style={{ marginLeft: "5px" }}>
                            Web Application
                          </span>
                        </InputGroup>
                      </Col>
                      <Col
                        lg={6}
                        xs={12}
                        style={{ margin: "10px", color: "black" }}
                      >
                        {" "}
                        <InputGroup>
                          <InputGroup.Checkbox
                            aria-label="Radio button for following text input"
                            style={{
                              border: "none",
                              size: "xs",
                            }}
                          />
                          <span style={{ marginLeft: "5px", color: "black" }}>
                            Static Website
                          </span>
                        </InputGroup>
                      </Col>{" "}
                    </Row>
                    <Row>
                      <Col
                        lg={6}
                        xs={12}
                        style={{ margin: "10px", color: "black" }}
                      >
                        {" "}
                        <InputGroup>
                          <InputGroup.Checkbox
                            aria-label="Radio button for following text input"
                            style={{ border: "none" }}
                          />
                          <span style={{ marginLeft: "5px" }}>Brand Logo</span>
                        </InputGroup>
                      </Col>
                      <Col
                        lg={6}
                        xs={12}
                        style={{ margin: "10px", color: "black" }}
                      >
                        {" "}
                        <InputGroup>
                          <InputGroup.Checkbox
                            aria-label="Radio button for following text input"
                            style={{ border: "none" }}
                          />
                          <span style={{ marginLeft: "5px" }}>Any other</span>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Col lg={3}>
                  <p style={{ marginTop: 30 }}>
                    Please Elaborate Your Project?
                  </p>

                  <Form.Control
                    size="lg"
                    type="text"
                    id="message"
                    placeholder="Your message"
                  />

                  <Form.Group
                    controlId="exampleForm.ControlTextarea1"
                    style={{ margintop: -10, marginRight: 10 }}
                  ></Form.Group>
                  <div className="mb-2">
                    <Button
                      style={{
                        backgroundColor: "black",
                        borderRadius: 1,
                        border: "none",
                        width: 150,
                      }}
                      size="lg"
                      onClick={enquiry}
                    >
                      Send
                    </Button>{" "}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row
          className="justify-content-space-between"
          style={{
            marginTop: 170,
            marginBottom: 190,
            fontWeight: 500,
            justifyContent: "flex-start",
          }}
        >
          <Col md={12} lg={6} xs={12}>
            <h6 className="heading1">The Advantages</h6>
            <h4 className="heading2" style={{ marginTop: 30 }}>
              Why Choose us?
            </h4>
            <p style={{ fontWeight: 700, textAlign: "left" }}>
              {" "}
              Choose us because we know what you are looking for.
            </p>
          </Col>

          <Col md={6} lg={3} xs={12}>
            {" "}
            <h3 className="advantages-section" style={{ marginTop: 50 }}>
              Methodology
            </h3>
            <Row>
              <Col lg={12}>
                <Row style={{ marginTop: "6px" }}>
                  <Col xs={1} md={2} lg={2}>
                    <img src={tick} style={{ height: 20 }} />
                  </Col>
                  <Col xs={11} md={10} lg={10}>
                    <p style={{ marginTop: "2px", color: "grey" }}>
                      Mobile First Approach
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col xs={1} md={2} lg={2}>
                    <img src={tick} style={{ height: 20 }} />
                  </Col>
                  <Col xs={11} md={10} lg={10}>
                    <p style={{ marginTop: "2px", color: "grey" }}>
                      Flawless Design
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col xs={1} md={2} lg={2}>
                    <img src={tick} style={{ height: 20 }} />
                  </Col>
                  <Col xs={11} md={10} lg={10}>
                    <p style={{ marginTop: "2px", color: "grey" }}>
                      Intense Research
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col xs={1} md={2} lg={2}>
                    <img src={tick} style={{ height: 20 }} />
                  </Col>
                  <Col xs={11} md={10} lg={10}>
                    <p style={{ marginTop: "2px", color: "grey" }}>
                      Creative Solutions
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={3} xs={12} style={{ textAlign: "left" }}>
            {" "}
            <h3 className="advantages-section" style={{ marginTop: 50 }}>
              Toolkit
            </h3>
            <Row style={{ marginTop: "15px" }}>
              <Col xs={1} md={2} lg={2}>
                <img src={tick} style={{ height: 20 }} />
              </Col>
              <Col xs={11} md={10} lg={10}>
                <p style={{ marginTop: "2px", color: "grey" }}>Modern Tools</p>
              </Col>
            </Row>
            <Row style={{ marginTop: "5px" }}>
              <Col xs={1} md={2} lg={2}>
                <img src={tick} style={{ height: 20 }} />
              </Col>
              <Col xs={11} md={10} lg={10}>
                <p style={{ marginTop: "2px", color: "grey" }}>
                  Advance Technologies
                </p>
              </Col>
            </Row>
            <Row style={{ marginTop: "5px" }}>
              <Col xs={1} md={2} lg={2}>
                <img src={tick} style={{ height: 20 }} />
              </Col>
              <Col xs={11} md={10} lg={10}>
                <p style={{ marginTop: "2px", color: "grey" }}>
                  Universal Standard
                </p>
              </Col>
            </Row>
            <Row style={{ marginTop: "5px" }}>
              <Col xs={1} md={2} lg={2}>
                <img src={tick} style={{ height: 20 }} />
              </Col>
              <Col xs={11} md={10} lg={10}>
                <p style={{ marginTop: "2px", color: "grey" }}>low Cost</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="rev" style={{ marginTop: 90, marginBottom: 100 }}>
          <Col lg={9}>
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                  eventKey="9"
                >
                  What can you help me with?{" "}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="9">
                  <Card.Body>
                    In terms of project development we prepare server-side
                    architecture, front end, back end, graphic and UX design,
                    and create back-office consoles. We are also happy to advise
                    our customers in terms of budgeting, scheduling, risk
                    management, and business model creation.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                  eventKey="1"
                >
                  How do I create a product with you?{" "}
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey="1"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                >
                  <Card.Body>
                    If you have an idea contact us by our online form, e-mail or
                    phone. We'll meet and talk it over. Just be sure to prepare
                    as much info about your idea as possible, it will smoothen
                    the meeting and benefit further cooperation. If you don't
                    know how to get around to it, go ahead and read our blog
                    entry on how to write a project brief.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                  eventKey="5"
                >
                  How much time will it take for you to make my app?{" "}
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey="5"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                >
                  <Card.Body>
                    To give you a precise answer we must prepare a project scope
                    and create a budget. We shall tell you how much of that
                    scope can be completed within the budget and we'll be able
                    to estimate how long will it take after at least a month of
                    work. Don't worry, if you're not happy with our work after
                    two weeks you can retract from the project with no
                    penalties, this is our trial period and we believe that any
                    reliable software house should give you one.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                  eventKey="4"
                >
                  Do I need to be tech-savvy to cooperate with you and order a
                  software application?{" "}
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey="4"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                >
                  <Card.Body>
                    You certainly do not have to be a software development
                    expert, that's what we're here for :) We welcome your
                    experience in that matter if you happen to have any, but are
                    prepared to guide you through the whole process and explain
                    everything. Focus on your needs and we'll deliver what you
                    require.
                  </Card.Body>
                </Accordion.Collapse>{" "}
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                  eventKey="3"
                >
                  Should I create a mobile or a web app?
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey="3"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                >
                  <Card.Body>
                    Both have their benefits and flaws. Mobile apps are surely
                    more expensive but can provide you with many more data
                    collecting, monetisation capabilities than web applications.
                    Progressive Web Apps are a good compromise between a mobile
                    and web app — you can learn more about PWA on our blog. But
                    remember that sooner or later you may need both mobile and
                    web app and when that moment comes we are here for you :)
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                  as={Button}
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                  variant="link"
                  eventKey="6"
                >
                  What do I need to know before contacting you?{" "}
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey="6"
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                >
                  <Card.Body>
                    Well, the most important thing to know is what do you want
                    to accomplish. Why do I need this software? What for? What
                    should it do? Having a clear vision in mind is crucial when
                    ordering a software application. You don't want to spend
                    many months developing it with us without being sure what
                    you need. If you have no experience in app development feel
                    free to read our blog entry on that topic. We'll also be
                    glad to help you get started, building projects from scratch
                    is nothing new for us.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col lg={3} xs={12} md={3}>
            <Row className="justify-content-left">
              <Col md={12} lg={10} xs={10}>
                <h6 className="heading1">Have Questions? Look here!</h6>
                <h4
                  className="heading2"
                  style={{
                    marginTop: 20,
                  }}
                >
                  FAQ
                </h4>
                <p className="contact-text">Unable to find your question?</p>
                <a href="#contactus">
                  {" "}
                  <Button
                    style={{
                      backgroundColor: "black",
                      border: "none",
                      borderRadius: "1",
                      marginBottom: "20px",
                    }}
                  >
                    Contact Us!
                  </Button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Home;
