// import React, { useState, Component } from "react";
// import {
//   Collapse,
//   Navbar,
//   NavbarToggler,
//   NavbarBrand,
//   Nav,
//   NavItem,
//   NavLink,
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
//   NavbarText,
//   ButtonToggle,
// } from "reactstrap";
// import { Container, Row, Col } from "reactstrap";
// import "./header.css";
// import {
//   Button,
//   Card,
//   CardBody,
//   CardText,
//   CardGroup,
//   CardTitle,
// } from "reactstrap";
// import user from "../Assets/Main/Hero/user.svg";
// import StripsCircled from "../Assets/Main/Hero/StripsCircled.svg";
// import Stripe_BG from "../Assets/Main/Hero/Stripe_BG.svg";
// import woman from "../Assets/Images/WomanPosing/woman.jpg";
// import GradBG from "../Assets/Main/Hero/GradBG.svg";

// const Header = (props) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggle = () => setIsOpen(!isOpen);

//   return (
//     <div>
//       <Container fluid={true}>
//         <Row>
//           <Col md="12" xs="10">
//             <Navbar light expand="md">
//               <NavbarBrand
//                 href="/"
//                 style={{
//                   fontSize: 32,
//                   fontFamily: "sans-serif",
//                   fontWeight: 700,
//                 }}
//               >
//                 Texerd
//               </NavbarBrand>
//               <NavbarToggler onClick={toggle} />
//               <Collapse isOpen={isOpen} navbar>
//                 <Nav className="mr-auto" navbar>
//                   <NavItem>
//                     <NavLink href="/components/"></NavLink>
//                   </NavItem>
//                   <NavItem>
//                     <NavLink href="https://github.com/reactstrap/reactstrap"></NavLink>
//                   </NavItem>
//                   <UncontrolledDropdown nav inNavbar></UncontrolledDropdown>
//                 </Nav>
//                 <Nav navbar className="header">
//                   <NavItem>
//                     <NavLink>Home &nbsp; &nbsp; &nbsp; </NavLink>
//                   </NavItem>
//                   <NavItem>
//                     <NavLink href="/Services/">
//                       Services &nbsp; &nbsp; &nbsp;{" "}
//                     </NavLink>
//                   </NavItem>{" "}
//                   <NavItem>
//                     <NavLink href="/Work/">
//                       Our Work &nbsp; &nbsp; &nbsp;{" "}
//                     </NavLink>
//                   </NavItem>{" "}
//                   <NavItem>
//                     <NavLink href="/Technologies/">
//                       Technologies &nbsp; &nbsp; &nbsp;{" "}
//                     </NavLink>
//                   </NavItem>
//                   <NavItem>
//                     <NavLink href="/Careers/">
//                       Careers &nbsp; &nbsp; &nbsp;{" "}
//                     </NavLink>
//                   </NavItem>
//                   <NavItem>
//                     <NavLink href="/About/">
//                       About &nbsp; &nbsp; &nbsp;{" "}
//                     </NavLink>
//                   </NavItem>

//                 </Nav>
//               </Collapse>
//             </Navbar>
//           </Col>
//         </Row>
//       </Container>
//       <Container fluid={true}>
//         <Row>
//           <Col md={6} xs={6} sm={5}>
//             <img
//               src={StripsCircled}
//               style={{
//                 width: 200,
//                 height: 300,
//                 marginTop: 40,
//               }}
//             />
//             <h1
//               style={{
//                 fontFamily: "inherit",
//                 fontSize: 68,
//                 letterSpacing: -4,
//                 lineHeight: -18,
//                 fontWeight: 700,
//               }}
//               className="centered"
//             >
//               {" "}
//               Creative Digital Business Solutions
//             </h1>
//             <Button
//               color="primary"
//               className="header-button"
//               style={{
//                 color: "white",
//                 fontWeight: "medium",
//                 borderRadius: 2,
//                 width: 150,
//                 height: 38,
//               }}
//             >
//               Start a Project
//             </Button>
//             <p className="centered1">
//               cvbcfghb cfgjhbmb cvbcfghb <br />
//               cvbcfghb cfgjhbmb cvbcfghb cfgjhbmb cvbcfghb cfgjhbmb
//             </p>
//           </Col>
//           <Col md={5} sm={5} xs={12}>
//             <img src={Stripe_BG} className="image1" />
//           </Col>
//         </Row>

// <Row>
// <Col md={6}>

// </Col>
// <Col md={6}>

// </Col>

// </Row>

//       </Container>
//     </div>
//   );
// };

// export default Header;

import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { Container, Navbar } from "react-bootstrap";
import { NavbarBrand } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import user from "../Assets/Main/Hero/user.svg";
import XLongCB from "../Assets/Main/Hero/XLongCB.png";
import { BsFillPersonFill } from "react-icons/bs";

import { Button } from "react-bootstrap";
import "./header.css";
function Header() {
  const [expanded, setExpand] = useState(false);

  return (
    <div className="header-nav">
      <Navbar
        expanded={expanded}
        expand="lg"
        className="justify-content-between"
      >
        <NavbarBrand>
          <Link to="/">
            {" "}
            <img
              className="logoo"
              src={XLongCB}
              style={{
                width: 170,
                height: 70,
              }}
            />
          </Link>
        </NavbarBrand>
        <Navbar.Toggle
          onClick={() => setExpand(expanded ? false : "expanded")}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" style={{ marginTop: "10px" }}>
            <Nav.Link onClick={() => setExpand(false)} className="nav-link">
              <Link
                style={{ textDecoration: "none" }}
                className="nav-hov"
                to=""
              >
                Home &nbsp;
              </Link>
            </Nav.Link>
            <Nav.Link onClick={() => setExpand(false)}>
              <Link
                to="/services"
                style={{ textDecoration: "none" }}
                className="nav-hov"
              >
                Services &nbsp;
              </Link>
            </Nav.Link>
            <Nav.Link onClick={() => setExpand(false)} className="nav-link">
              <Link
                to="/work"
                style={{ textDecoration: "none" }}
                className="nav-hov"
              >
                Our Work &nbsp;
              </Link>
            </Nav.Link>
            <Nav.Link onClick={() => setExpand(false)}>
              <Link
                to="/technologies"
                style={{ textDecoration: "none" }}
                className="nav-hov"
              >
                Technologies &nbsp;
              </Link>
            </Nav.Link>
            <Nav.Link onClick={() => setExpand(false)}>
              {" "}
              <Link
                to="/careers"
                style={{ textDecoration: "none" }}
                className="nav-hov"
              >
                Careers &nbsp;
              </Link>
            </Nav.Link>
            <Nav.Link onClick={() => setExpand(false)}>
              <Link
                to="/about"
                style={{ textDecoration: "none" }}
                className="nav-hov"
              >
                About &nbsp;
              </Link>
              &nbsp; &nbsp;&nbsp;
            </Nav.Link>
            {/* <Button
              color="primary"
              className="header-button"
              style={{
                color: "white",
                fontWeight: "medium",
                borderRadius: 2,
                marginTop: 6,
                height: 38,
              }}
            >
              <BsFillPersonFill /> Contact
            </Button>{" "} */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
