import React from "react";
import "./App.css";
import Header from "./components/Header";
import Services from "./pages/Services";
import Home from "./pages/Home";
import { HashRouter as Router, Switch, Route, BrowserRouter } from "react-router-dom";
// import { browserHistory } from 'react-router';
import Footer from "./components/Footer";
import Technologies from "./pages/Technologies";
import About from "./pages/About";
import Work from "./pages/Work";
import Project from "./pages/Project";
import Careers from "./pages/Careers";
import Project1 from "./pages/Project1";
import Project2 from "./pages/Project2";
import Project3 from "./pages/Project3";
import Project4 from "./pages/Project4";
import Project5 from "./pages/Project5";
function App() {
  return (
    <div style={{ overflowX: "hidden" }}>
      {" "}
      <BrowserRouter>
        <Switch>
          <Route path="/services">
            <Header />
            <Services />
            <Footer />
          </Route>
          <Route path="/project">
            <Header />
            <Project /> <Footer />
          </Route>
          <Route path="/project1">
            <Header />
            <Project1 />
            <Footer />
          </Route>
          <Route path="/project2">
            <Header />
            <Project2 />
            <Footer />
          </Route>
          <Route path="/project3">
            <Header />
            <Project3 />
            <Footer />
          </Route>
          <Route path="/project4">
            <Header />
            <Project4 />
            <Footer />
          </Route>
          <Route path="/project5">
            <Header />
            <Project5 />
            <Footer />
          </Route>

          <Route path="/about">
            <Header />
            <About />
            <Footer />
          </Route>

          <Route path="/work">
            <Header />
            <Work />
            <Footer />
          </Route>
          <Route path="/careers">
            <Header />
            <Careers /> <Footer />
          </Route>
          <Route path="/technologies">
            <Header />
            <Technologies /> <Footer />
          </Route>
          <Route path="">
            <Header />
            <Home />
            {/* <Footer /> */}
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
