import React, { useEffect } from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import king from "../Assets/Project/king.svg";
import iphone from "../Assets/Project/iphone.svg";
import image1 from "../Assets/Images/image1.png";
import "./project.css";
import colorten from "../Assets/Images/colorten.png";
import color10 from "../Assets/Images/color10.png";
import color11 from "../Assets/Images/color11.png";
import color12 from "../Assets/Images/color12.png";
import colortwo from "../Assets/Images/colortwo.png";
import uday from "../Assets/Images/uday.png";
import udayy from "../Assets/Images/udayy.png";
import udayyy from "../Assets/Images/udayyy.png";
import app2 from "../Assets/Images/app2.jpeg";
import app3 from "../Assets/Images/app3.jpeg";
import app4 from "../Assets/Images/app4.jpeg";
import rocket from "../Assets/Careers/rocket.svg";
import colorthree from "../Assets/Images/colorthree.png";
import colorfour from "../Assets/Images/colorfour.png";
import Valerie from "../Assets/Images/Valerie.jpg";
import GoogleMaps from "../Assets/Images/GoogleMaps.png";
import firebasesvg1 from "../Assets/Images/firebasesvg1.svg";
import dart from "../Assets/Images/dart.svg";
import fluttersvg from "../Assets/Images/fluttersvg.svg";
import xd from "../Assets/Services/WebDesign/XD.svg";
import photoshop from "../Assets/Services/WebDesign/Photoshop.svg";

import nodejs from "../Assets/Services/WebDev/nodejs.svg";
import angular from "../Assets/Services/WebDev/angular.svg";
import tailwind from "../Assets/Services/WebDev/tailwind.svg";
import Techcard from "../components/Techcard";
import { FiSearch } from "react-icons/fi";

function Project2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Row
        className="justify-content-center"
        style={{ marginBottom: "180px", marginTop: "30px" }}
      >
        <Col lg={5} xs={10} md={5} style={{ marginTop: "60px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "70px",
              fontWeight: "700",
            }}
          >
            Uday Realty{" "}
          </h1>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            Uday Realty is an ISO 9001-2015 certified company, founded by
            experienced individuals who are highly committed to providing
            ultimate service experience and valuable properties. They strive to
            be a leading company in the Hyderabad Real-Estate market with our
            deep rooted entrepreneurial spirit. Meeting the expectations of our
            customers is our primary goal. They offer HUDA, HMDA, DTCP approved
            layouts, residential apartments, residential villas and commercial
            spaces.
          </p>
          <div style={{ marginTop: "-50px" }}>
            <Button variant="outline-primary">Real Estate</Button>{" "}
            <Button variant="outline-warning">Design</Button>{" "}
            <Button variant="outline-danger">Development</Button>{" "}
          </div>
        </Col>
        <Col lg={5} xs={12} md={5}>
          <img src={udayyy} style={{ width: "100%", height: "400px" }} />
        </Col>
      </Row>

      <Row
        className="justify-content-center"
        style={{ marginBottom: "200px", marginTop: "100px" }}
      >
        <Col lg={10} md={10} xs={10}>
          <h2 className="about-head">Our Core Values</h2>
          <Row
            className="justify-content-between"
            style={{ marginTop: "50px" }}
          >
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={iphone}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
                <p style={{ fontWeight: 700, fontSize: "20px" }}> 8 Screens</p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",

                    fontWeight: "500",
                    color: "grey",
                  }}
                >
                  {" "}
                  These different screens clearly explain about the firm and
                  also gives investment knowledge. The home page also plays a
                  video.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={king}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Royal Design</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Royal Design with golden and black colour gives a luxorious
                look.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div
                className="justify-content-center"
                style={{ fontSize: "32px" }}
              >
                {" "}
                <FiSearch />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Pixel Perfect</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                All the content and images are very well responsive on different
                mobiles,laptop screens and tabs.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={rocket}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Modern Tools</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Have made use of latest tailwind and Angular that is very
                robust.
              </p>
            </Col>
          </Row>{" "}
        </Col>
      </Row>
      <h2 className="about-head">Typography</h2>

      <Row className="justify-content-center">
        <Col lg={5} xs={10} md={5} style={{ marginTop: "100px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Spectral
          </h1>
          <Row>
            <Col lg={2} xs={6}>
              <h1
                className="project-font"
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",

                  fontWeight: "300",
                }}
              >
                Light
              </h1>
            </Col>
            <Col lg={3} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "400",
                }}
              >
                Regular
              </h1>
            </Col>{" "}
            <Col lg={4} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "550",
                }}
              >
                Semi-Bold
              </h1>
            </Col>
            <Col lg={3} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "800",
                }}
              >
                Bold
              </h1>
            </Col>
          </Row>
          <p
            style={{
              display: "flex",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            At Texerd,we are team of young, dynamic and vibrant peopleAt
          </p>
        </Col>
        <Col lg={4} xs={10} md={5}>
          <h1
            style={{
              fontFamily: "revert",
              color: "#E7E7E7",
              fontSize: "200px",
            }}
          >
            Aa
          </h1>{" "}
        </Col>
        <Col lg={5} xs={10} md={5} style={{ marginTop: "100px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontFamily: "monospace",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Montserrat
          </h1>

          <Row>
            <Col lg={3} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "monospace",

                  fontWeight: "400",
                }}
              >
                Regular
              </h1>
            </Col>{" "}
            <Col lg={4} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "monospace",

                  fontWeight: "550",
                }}
              >
                Semi-Bold
              </h1>
            </Col>
            <Col lg={3} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "monospace",
                  fontWeight: "800",
                }}
              >
                Bold
              </h1>
            </Col>
          </Row>
          <p
            style={{
              display: "flex",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            At Texerd,we are team of young, dynamic and vibrant peopleAt
          </p>
        </Col>
        <Col lg={4} xs={11} md={5}>
          <h1
            style={{
              fontFamily: "monospace",
              color: "#E7E7E7",
              fontSize: "200px",
            }}
          >
            Aa
          </h1>{" "}
        </Col>
      </Row>
      <Container>
        <h2 className="headingtwo">Colors</h2>
      </Container>
      <Row style={{ justifyContent: "center", marginBottom: "200px" }}>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img variant="top" src={color10} style={{ height: "180px" }} />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>#826344</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img variant="top" src={color11} style={{ height: "180px" }} />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>#BB9B66</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colortwo}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>#1C1C1C</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img variant="top" src={color12} style={{ height: "180px" }} />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>#d0b392</h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Container>
        <Techcard
          techtitle="Tools & Technologies"
          subtitle="XD"
          subtitle1="nodejs"
          subtitle2="Angular"
          subtitle3="Tailwind"
          techimg={xd}
          techimg1={nodejs}
          techimg2={angular}
          techimg3={tailwind}
          style={{ width: "500px" }}
        />
        <h2 className="headingtwo">Clients</h2>
        <Row style={{ marginBottom: "75px", justifyContent: "center" }}>
          <Col lg={8}>
            <Card className="shadow">
              <Row className="justify-space-around">
                <Col lg={3} md={4} xs={12} className="project-test">
                  <img src={Valerie} className="project-img" />
                  <br />
                  <h3 className="headingthree">Sakshi Goyal</h3>
                  <p className="project-text">Chief President</p>
                </Col>
                <Col lg={8} xs={12} md={6} className="project-eleven">
                  "dfjhfdbnbjhytre At Texerd,we are team of young, dynamic and
                  vibrant people coming from technical and creative fields and
                  work with dedication to digitally create new or modify"
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <h2 className="headingtwo">Screenshots</h2>
        </Row>
        <Row style={{ marginBottom: "100px" }}>
          <Col
            lg={12}
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img height="400px" src={udayyy} />
          </Col>

          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
            lg={12}
            xs={12}
            md={6}
          >
            <img height="400px" src={uday} />
          </Col>
        </Row>
        <Row>
          {" "}
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            lg={12}
            xs={12}
            md={6}
          ></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Project2;
