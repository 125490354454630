import React, { useEffect } from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";

import "./test.css";
import womanvr from "../Assets/Services/UserResearch/womanvr.jpg";
import service1 from "../Assets/Services/UserResearch/service1.png";
import service3 from "../Assets/Services/UserResearch/service3.png";
import sernice2 from "../Assets/Services/UserResearch/sernice2.png";
import service10 from "../Assets/Services/WebDesign/service10.jpg";
import image1 from "../Assets/Services/UserResearch/1.jpg";
import image2 from "../Assets/Services/UserResearch/2.jpg";
import image3 from "../Assets/Services/UserResearch/3.jpg";
import image4 from "../Assets/Services/UserResearch/4.jpg";
import image5 from "../Assets/Services/UserResearch/5.jpg";
import Fonts from "../Assets/Services/WebDesign/Fonts.svg";
import XD from "../Assets/Services/WebDesign/XD.svg";
import Illustrator from "../Assets/Services/WebDesign/Illustrator.svg";
import Photoshop from "../Assets/Services/WebDesign/Photoshop.svg";
import safar from "../Assets/Services/WebDev/safar.jpg";
import angular from "../Assets/Services/WebDev/angular.svg";
import nodejs from "../Assets/Services/WebDev/nodejs.svg";
import react from "../Assets/Services/WebDev/react.svg";
import tailwind from "../Assets/Services/WebDev/tailwind.svg";
import visual from "../Assets/Images/visual.jpg";
import vizz from "../Assets/Services/Viz/vizz.jpg";
import google from "../Assets/Services/Viz/google.svg";
import python from "../Assets/Services/Viz/python.svg";
import power from "../Assets/Services/Viz/power.svg";

import Servicecard from "../components/Servicecard";
import "./services.css";
import StripesBlue from "../Assets/Main/Hero/StripesBlue.png";
import { Carousel } from "react-bootstrap";

function Services(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div style={{ marginBottom: "150px" }}>
        <Row style={{ marginTop: "160px" }} className="justify-content-center">
          <Col md={12} lg={12} xs={12}>
            <h1 className="heading">
              Professional Digital and <br />
              Web Development Services
            </h1>
          </Col>
          <div className="overlay">
            <img
              height="180px"
              width="180px"
              style={{ opacity: "0.6" }}
              src={StripesBlue}
            />
          </div>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              {" "}
              We are a one stop destination for all your TECH needs. From static
              website to high end mobile
              <br /> application we are here to cater all your business
              problems. Let us be a part in taking your business to the next
              level
            </p>
          </Col>
        </Row>
      </div>

      <Container>
        <Servicecard
          title="User Research"
          img={womanvr}
          img2={service1}
          img3={sernice2}
          img4={service3}
          subcontext="  UX (user experience) research is the systematic study of target users and'
           their requirements, to add realistic contexts and insights to design processes.
            "
          content="  UX researchers adopt various methods to uncover problems and design opportunities."
          content3="Doing so, they reveal valuable information which can be fed into the design process."
        />
        <Servicecard
          title="Design"
          img={service10}
          img2={Fonts}
          img1={XD}
          img3={Illustrator}
          img4={Photoshop}
          subcontext="The research and prototype stages of the design process can generate new product ideas."
          content=" Design opens up valuable opportunities for businesses and bring some significant business benefits."
          content3="Whether it's a new product or a website redesign, fresh design can keep your customers interested.
       "
        />
        <Servicecard
          title="Development"
          img={safar}
          img2={angular}
          img1={nodejs}
          img3={react}
          img4={tailwind}
          subcontext="We develop creative and scalable websites,designed to deliver strong business results.
          "
          content="Websites help you align with what’s needed in the long term.
          "
          content1="Leveraging web technologies to build scalable websites.
          .
          "
          content3="Integrating and using APIs that are consistent and reusable.
          "
          content4="webapps for businesses and users across varied platforms."
        />
        <Servicecard
          title="Visualization"
          img={vizz}
          img2={google}
          img3={python}
          img4={power}
          subcontext="A visual summary of information makes it easier to identify patterns .
          
          "
          content=" Visually track, analyze and display key performance indicators, metrics."
          content1="We offer the most efficient way to track multiple data sources"
          content3="Monitor and improve your business through visual representations
        "
          content4="Charts and graphs make communicating data findings easier.
        "
        />
        <Servicecard
          title="Automation"
          img={visual}
          img2={google}
          img3={python}
          img4={power}
          subcontext="Automate your boring and inane stuff with our digital solutions.What could take hours and days for you could be done within minutes.
                    "
          content="Allow your organization to script custom automation and reap time savings."
          content4="Charts and graphs make communicating data findings easier.
        "
        />
        <Servicecard
          title="Cloud Services"
          img={image3}
          // img2={google}
          // img3={python}
          // img4={power}
          subcontext="Cloud services for IT consulting encompass a wide range of offerings that leverage cloud computing technologies to deliver solutions and support to clients.  "
          content="We offer services like Infrastructure as a Service (IaaS),
          Platform as a Service (PaaS),
          Software as a Service (SaaS)."
          content4="We also offer Cloud Migration Services,
          Cloud Security Services.
        "
        />
        <Servicecard
          title="Managed IT Services"
          img={image5}
          // img2={google}
          // img3={python}
          // img4={power}
          subcontext="We allow businesses to focus on their core operations while we manage all the IT related services."
          content="We provide Remote Monitoring and Management (RMM),24/7 Help Desk Support,IT Asset Management,Backup and Disaster Recovery."
          content4="We offer businesses the benefits of predictable costs, enhanced security, proactive maintenance, access to specialized expertise, scalability, and improved overall IT performance and reliability.
        "
        />
         <Servicecard
          title="IT Consulting Services"
          img={image4}
          // img2={google}
          // img3={python}
          // img4={power}
          subcontext="Our services are designed to address various aspects of technology planning, implementation, management, and support to align IT initiatives with business objectives and improve overall efficiency, productivity, and competitiveness. "
          content="We offer IT Strategy and Planning,Technology Evaluation and Selection,IT Project Management."
          content4="We help businesses leverage technology effectively, overcome challenges, capitalize on opportunities, and achieve sustainable growth and success in today's digital age.
        "
        />
        <Servicecard
          title="Managed Security Services"
          img={image2}
          // img2={google}
          // img3={python}
          // img4={power}
          subcontext="We offer cybersecurity solutions to proactively monitor, manage, and enhance an organization's security posture. "
          content="We offer Security Monitoring and Threat Detection,Vulnerability Management,Endpoint Security, Data Protection and Encryption and many more."
          content4="These services are particularly beneficial for businesses facing increasingly sophisticated cyber threats and regulatory challenges, allowing them to focus on core operations.
        "
        />
        <Servicecard
          title="Networking and Programming"
          img={image1}
          // img2={google}
          // img3={python}
          // img4={power}
          subcontext="Networking involves designing, managing, and securing computer networks to enable efficient data communication."
          content="Programming focuses on creating software applications and solutions."
          content4="Both networking and programming are integral to building robust IT systems, applications, and solutions that meet the demands of modern businesses and technology environments. 
        "
        />
        <Row
          className="justfiy-content-center"
          style={{ marginBottom: "50px" }}
        >
          <Col lg={12} md={12} xs={12}>
            <h2 style={{ color: "grey", textAlign: "center", fontWeight: 500 }}>
              {" "}
              We are Planning to introduce <br /> new services soon!
            </h2>
            <br />
            <h4 style={{ color: "grey", textAlign: "center" }}>Stay Around!</h4>
          </Col>
        </Row>{" "}
      </Container>
    </div>
  );
}

export default Services;
