import React from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import XLongCW from "../Assets/Main/Hero/XLongCW.png";
import { FaTwitterSquare, FaLinkedin, FaFacebookSquare } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
function Footer() {
  return (
    <div>
      <Card
        style={{ backgroundColor: "black", padding: "20px", borderRadius: 0 }}
      >
        <Row classname="justify-content-center">
          <Col lg={12} style={{ textAlign: "center", margintop: "100px" }}>
            <img
              src={XLongCW}
              style={{
                width: 220,
                marginTop: "50px",
                marginBottom: "50px",
                height: 120,
              }}
            />
          </Col>
        </Row>
        <Container>
          <Row style={{ color: "grey" }} className="justify-content-around">
            <Col lg={4}>
              {" "}
              <MdLocationOn />
              Melbourne
            </Col>
            <Col lg={2}>
              <MdLocationOn /> Hyderabad
            </Col>
          </Row>
        </Container>
        <hr
          style={{
            color: "grey",
            backgroundColor: "grey",
            justifyContent: "center",
          }}
        />
        <Row style={{ color: "grey" }} className="justify-content-center">
          <Col lg={5}>Copyright 2020 by Texerd | All Rights Reserved.</Col>
          <Col lg={4}> Terms Privacy</Col>
          <Col lg={3}>
            Reach Out to Us &nbsp; <FaTwitterSquare /> &nbsp;
            <FaLinkedin /> &nbsp; <FaFacebookSquare />
          </Col>
        </Row>
      </Card>

      {/* <Card style={{ backgroundColor: "black", width: "100%" }}>
        <Row class style={{ marginTop: "10px" }}>
          <Col xs={10} md={12} lg={6}>
            <img
              src={XLongCW}
              style={{
                width: 170,
                height: 70,
              }}
            />
            <p style={{ color: "white" }}>
              At Texerd,we are team of young, dynamic and vibrant people coming
              from technical and creative fields and work with dedication to
              digitally create new or modify existing business processes,culture
              and customer experiences to meet changing business and market
              requirements.We believe,what we design,create and produce will
              help you reap umpteen profits.
            </p>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <Row
              style={{
                marginTop: "30px",
                color: "white",
                // marginLeft: "50px",
              }}
            >
              <Col xs={3} md={4} lg={4}>
                <h5> Services</h5>
              </Col>
              <Col xs={4} md={4} lg={4}>
                <h5> Our Work</h5>
              </Col>
              <Col xs={5} md={4} lg={4}>
                <h5> Technologies</h5>
              </Col>
            </Row>{" "}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={10} xs={10} md={10}>
            <hr
              style={{
                color: "grey",
                backgroundColor: "grey",
                justifyContent: "center",
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            color: "grey",
            textAlign: "left",
            marginBottom: "50px",
            marginTop: "30px",
          }}
        >
          <Col lg={6} md={5} xs={6}>
            Copyright 2020 by Texerd | All Rights Reserved.
          </Col>
          <Col lg={3} md={3} xs={6} style={{ marginTop: "10px" }}>
            Terms Privacy
          </Col>
          <Col lg={2} md={4} xs={6} style={{ marginTop: "10px" }}>
            Reach out to us
          </Col>
        </Row>
      </Card>{" "} */}
    </div>
  );
}

export default Footer;
