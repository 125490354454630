import React from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import "./services.css";
import "./tech.css";
import "./test.css";
import StripesPurpl from "../Assets/Main/Hero/StripesPurpl.png";
import angular from "../Assets/Services/WebDev/angular.svg";
import nodejs from "../Assets/Services/WebDev/nodejs.svg";
import react from "../Assets/Services/WebDev/react.svg";
import tailwind from "../Assets/Services/WebDev/tailwind.svg";
import Techcard from "../components/Techcard";
import Fonts from "../Assets/Services/WebDesign/Fonts.svg";
import XD from "../Assets/Services/WebDesign/XD.svg";
import Illustrator from "../Assets/Services/WebDesign/Illustrator.svg";
import Photoshop from "../Assets/Services/WebDesign/Photoshop.svg";
import google from "../Assets/Services/Viz/google.svg";
import python from "../Assets/Services/Viz/python.svg";
import power from "../Assets/Services/Viz/power.svg";
import tableau from "../Assets/Services/Viz/tableau.svg";

function Technologies() {
  return (
    <div>
      <div style={{ marginBottom: "150px" }}>
        <Row style={{ marginTop: "160px" }} className="justify-content-center">
          <Col md={12} lg={12} xs={12}>
            <h1 className="heading">
              We leverage our Modern Tools <br />
              to meet our requirments{" "}
            </h1>
          </Col>
          <div className="overlay">
            <img
              height="180px"
              width="180px"
              style={{ opacity: "0.6" }}
              src={StripesPurpl}
            />
          </div>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              {" "}
              You have the idea and we have just the right resources with rich
              industry experience that your software development project needs.
              <br />
              We develop successful software solutions meant for web and mobile
              platforms for startups and enterprises
              <br /> with guaranteed delivery.
            </p>
          </Col>
        </Row>
      </div>
      <Container>
        <Techcard
          techtitle="Development"
          subtitle="Angular"
          subtitle1="Node JS"
          subtitle2="React"
          subtitle3="Tailwind css"
          text1="google"
          text2="Facebook"
          text3="Node"
          text4="Tailwind"
          techimg={angular}
          techimg1={nodejs}
          techimg2={react}
          techimg3={tailwind}
        />
        <Techcard
          techtitle="Design"
          subtitle="XD"
          subtitle1="Fonts"
          subtitle2="Illustrator"
          subtitle3="Photoshop"
          text1="Adobe"
          text2="Adobe"
          text3="Adobe"
          text4="Adobe"
          techimg={XD}
          techimg1={Fonts}
          techimg2={Illustrator}
          techimg3={Photoshop}
        />
        <Techcard
          techtitle="Visualization"
          subtitle="Analytics"
          subtitle1="Python"
          subtitle2="Bi"
          subtitle3="Tableau"
          text1="Google"
          text2="Python Software Foundation"
          text3="Microsoft"
          text4="Salesforce"
          techimg={google}
          techimg1={python}
          techimg2={power}
          techimg3={tableau}
        />
      </Container>
    </div>
  );
}

export default Technologies;
