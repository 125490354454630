import React, { useEffect } from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";

import image1 from "../Assets/Images/image1.png";
import "./project.css";
import king from "../Assets/Project/king.svg";
import iphone from "../Assets/Project/iphone.svg";
import colorone from "../Assets/Images/colorone.png";
import colortwo from "../Assets/Images/colortwo.png";
import statotech from "../Assets/Images/statotech.png";
import statotech5 from "../Assets/Images/statotech6.png";
import statotech3 from "../Assets/Images/statotech33.png";
import statotech2 from "../Assets/Images/statotech2.png";
import rocket from "../Assets/Careers/rocket.svg";
import colorthree from "../Assets/Images/colorthree.png";
import colorfour from "../Assets/Images/colorfour.png";
import Valerie from "../Assets/Images/Valerie.jpg";
import jd from "../Assets/Images/jd.png";
import css1 from "../Assets/Images/css1.png";
import html from "../Assets/Images/html.png";
import bootstrap from "../Assets/Images/bootstrap.png";
import nodejs from "../Assets/Services/WebDev/nodejs.svg";
import react from "../Assets/Services/WebDev/react.svg";
import tailwind from "../Assets/Services/WebDev/tailwind.svg";
import Techcard from "../components/Techcard";
import Fonts from "../Assets/Services/WebDesign/Fonts.svg";
import XD from "../Assets/Services/WebDesign/XD.svg";
import Illustrator from "../Assets/Services/WebDesign/Illustrator.svg";
import Photoshop from "../Assets/Services/WebDesign/Photoshop.svg";
import google from "../Assets/Services/Viz/google.svg";

function Project(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      {/* <h1>{props.name.name}</h1> */}

      <Row
        className="justify-content-center"
        style={{ marginBottom: "180px", marginTop: "30px" }}
      >
        <Col lg={5} xs={10} md={5} style={{ marginTop: "60px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "70px",
              fontWeight: "700",
            }}
          >
            StatoTech Solutions{" "}
          </h1>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            Statotech Solutions is believed to be one of the popular providers
            of IT services. The professional company is closely structured in
            such a way to offer more acquirable results and solutions for use in
            a variety of businesses, encountering problems in information and
            technology sector.
          </p>
          <div style={{ marginTop: "-50px" }}>
            <Button variant="outline-primary">Business</Button>{" "}
            <Button variant="outline-warning">Design</Button>{" "}
            <Button variant="outline-danger">Development</Button>{" "}
          </div>
        </Col>
        <Col lg={5} xs={12} md={5}>
          <img src={statotech} className="project-one" />
        </Col>
      </Row>

      <Row
        className="justify-content-center"
        style={{ marginBottom: "200px", marginTop: "100px" }}
      >
        <Col lg={10} md={10} xs={10}>
          <h2 className="about-head">Our Core Values</h2>
          <Row
            className="justify-content-between"
            style={{ marginTop: "50px" }}
          >
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={iphone}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
                <p style={{ fontWeight: 700, fontSize: "20px" }}>1 Screen</p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",

                    fontWeight: "500",
                    color: "grey",
                  }}
                >
                  {" "}
                  A Single Page Application with 5 different sections. About
                  Us,Why Choose Us,Services Offered,Contact Us,Header/Footer
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={king}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>
                Professional Design
              </p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Professional Design with fade in animation for images and
                content. The Google Map clearly displays the location of the
                company.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div
                className="justify-content-center"
                style={{ fontSize: "32px" }}
              >
                {" "}
                <FiSearch />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Pixel Perfect</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                All the content and images are very well responsive on different
                mobiles,laptop screens and tabs.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={rocket}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Modern Tools</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Have made use of latest Bootstrap 5 that is very robust.
              </p>
            </Col>
          </Row>{" "}
        </Col>
      </Row>
      <h2 className="about-head">Typography</h2>

      <Row className="justify-content-center">
        <Col lg={5} xs={10} md={5} style={{ marginTop: "100px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Nunito
          </h1>
          <Row>
            <Col lg={2} xs={6}>
              <h1
                className="project-font"
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "",

                  fontWeight: "300",
                }}
              >
                Light
              </h1>
            </Col>
            <Col lg={3} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "400",
                }}
              >
                Regular
              </h1>
            </Col>{" "}
            <Col lg={4} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "550",
                }}
              >
                Semi-Bold
              </h1>
            </Col>
            <Col lg={3} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "800",
                }}
              >
                Bold
              </h1>
            </Col>
          </Row>
          <p
            style={{
              display: "flex",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            At Texerd,we are team of young, dynamic and vibrant peopleAt
          </p>
        </Col>
        <Col lg={4} xs={10} md={5}>
          <h1
            style={{
              fontFamily: "revert",
              color: "#E7E7E7",
              fontSize: "200px",
            }}
          >
            Aa
          </h1>{" "}
        </Col>
        <Col lg={5} xs={10} md={5} style={{ marginTop: "100px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Monserrat
          </h1>

          <Row>
            <Col lg={2} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "300",
                }}
              >
                Light
              </h1>
            </Col>
            <Col lg={3} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "400",
                }}
              >
                Regular
              </h1>
            </Col>{" "}
            <Col lg={4} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "550",
                }}
              >
                Semi-Bold
              </h1>
            </Col>
            <Col lg={3} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "800",
                }}
              >
                Bold
              </h1>
            </Col>
          </Row>
          <p
            style={{
              display: "flex",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            At Texerd,we are team of young, dynamic and vibrant peopleAt
          </p>
        </Col>
        <Col lg={4} xs={11} md={5}>
          <h1
            style={{
              fontFamily: "revert",
              color: "#E7E7E7",
              fontSize: "200px",
            }}
          >
            Aa
          </h1>{" "}
        </Col>
      </Row>
      <Container style={{ marginTop: "100px" }}>
        <h2 className="about-head">Colors</h2>
      </Container>
      <Row style={{ justifyContent: "center", marginBottom: "200px" }}>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colorone}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>6161e8</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colortwo}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>6161e8</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colorthree}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>6161e8</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colorfour}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>6161e8</h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Container>
        <Techcard
          techtitle="Tools & Technologies"
          subtitle="JavaScript"
          subtitle1="CSS"
          subtitle2="Html"
          subtitle3="Bootstrap"
          text1="google"
          text2="Facebook"
          text3="Node"
          text4="Tailwind"
          techimg={jd}
          techimg1={css1}
          techimg2={html}
          techimg3={bootstrap}
        />
      </Container>
      <Container style={{ marginTop: "100px" }}>
        <h2 className="about-head">Clients</h2>
        <Row style={{ marginBottom: "75px", justifyContent: "center" }}>
          <Col lg={8}>
            <Card className="shadow">
              <Row className="justify-space-around">
                <Col lg={3} md={4} xs={12} className="project-test">
                  <img src={Valerie} className="project-img" />
                  <br />
                  <h3 className="headingthree">Sakshi Goyal</h3>
                  <p className="project-text">Chief President</p>
                </Col>
                <Col lg={8} xs={12} md={6} className="project-eleven">
                  " At Texerd,we are team of young, dynamic and vibrant people
                  coming from technical and creative fields and work with
                  dedication to digitally create new or modify"
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Container style={{ marginTop: "100px" }}>
          <h2 className="about-head">Screenshots</h2>
        </Container>
        <Row>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            lg={6}
            xs={12}
            md={6}
          >
            <img height="500px" src={statotech5} />
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            lg={6}
            xs={12}
            md={6}
          >
            <img height="500px" src={statotech2} />
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            lg={12}
            xs={12}
            md={6}
          >
            <img height="500px" src={statotech3} />
          </Col>
        </Row>

        <Row className="justify-content-center"> </Row>
      </Container>
    </div>
  );
}

export default Project;
