import React, { useState } from "react";
import {
  Button,
  Container,
  Modal,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import "./careers.css";
import emailjs from "emailjs-com";

import "./test.css";
import StripesRhom from "../Assets/Main/Hero/StripesRhom.png";
import Map from "../Assets/Careers/Map.png";
import flight from "../Assets/Careers/flight.svg";
import party from "../Assets/Careers/party.svg";
import rocket from "../Assets/Careers/rocket.svg";
import mission from "../Assets/Careers/mission.svg";
import king from "../Assets/Project/king.svg";
import iphone from "../Assets/Project/iphone.svg";
import { BiFemaleSign, BiTimeFive } from "react-icons/bi";
import careerPeople from "../Assets/Careers/careerPeople.jpg";
import { FiExternalLink } from "react-icons/fi";

import "./tech.css";
function Careers() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div style={{ marginBottom: "150px" }}>
        <Row style={{ marginTop: "160px" }} className="justify-content-center">
          <Col md={12} lg={12} xs={12}>
            <h1 className="heading">
              Hello We're Recruiting <br />
              Ready to join our Workforce
            </h1>
          </Col>
          <div className="overlay">
            <img
              height="180px"
              width="180px"
              style={{ opacity: "0.6" }}
              src={StripesRhom}
            />
          </div>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              {" "}
              Do you want to be a part of projects that want to bring change in
              lives and that create global presence? <br />
              We are waiting for you.
            </p>
          </Col>
        </Row>
      </div>
      <Row className="justify-content-center">
        <Col md={12} lg={12} xs={12} xl={8}>
          <h1 className="career-section1">Our Presence & Highlights</h1>
        </Col>
        <Col md={6} lg={8} xs={12} xl={8}>
          <img src={Map} className="career-image" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6} lg={2} xs={4}>
          <Card className="shadow-lg" className="career-card2">
            <p>Houstan,USA</p>
          </Card>
        </Col>
        <Col md={6} lg={2} xs={4}>
          <Card className="shadow" className="career-card">
            <p>
              Gender Equality <br /> <BiFemaleSign />
              50% <BiFemaleSign />
              50%
            </p>
          </Card>
        </Col>
        <Col md={6} lg={2} xs={4}>
          <Card className="shadow-lg" className="career-card3">
            <p>Job Satisfaction</p>
          </Card>
        </Col>{" "}
        <Col md={6} lg={2} xs={4}>
          <Card className="shadow-lg" className="career-card4">
            <p>
              Time Flexibility <br />
              <BiTimeFive /> 100%
            </p>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6} lg={2} xs={4}>
          <Card className="shadow-lg" className="career-card5">
            <p>Hyderabad,India</p>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={10} md={10} xs={10}>
          <Row
            className="justify-content-between"
            style={{ marginBottom: "150px" }}
          >
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={flight}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
                <p style={{ fontWeight: 700, fontSize: "20px" }}>
                  Work Remotely{" "}
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",

                    fontWeight: "500",
                    color: "grey",
                  }}
                >
                  {" "}
                  Work from anywhere,Anytime at ur own comfort. Working with us
                  if very comfortable.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={party}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>
                Fun Enviornment
              </p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Work like its your home. Working with us feels like playing your
                favourite sport with your best friends gang.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={rocket}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>
                Creative Peers
              </p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                At texerd,we come from creative backgrounds.Making your work
                look more beautiful and appealing.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={mission}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>One Target</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Small teams, global mission.Give everyone a global platform.
              </p>
            </Col>
          </Row>{" "}
        </Col>
      </Row>
      <Container>
        <Row
          className="justify-content-center"
          style={{ marginBottom: "150px" }}
        >
          <Col lg={4} xs={11} md={8}>
            <Card className="shadow-lg" className="career-cardtext">
              <h2>Work Culture</h2>
              <p style={{ color: "grey" }}>
                {" "}
                A world where anyone can belong anywhere starts with a workplace
                where you feel welcome and can contribute your best work. We
                Welcome everyone of u.
              </p>
            </Card>
          </Col>
          <img src={careerPeople} className="career-photo" />
        </Row>
        <h1 className="career-section1">Our Positions</h1>

        <Row
          className="justify-content-center"
          style={{ marginTop: "50px", marginBottom: "70px" }}
        >
          <Col md={12} lg={4} xs={10}>
            <Card
              className="career-sec2"
              style={{
                border: "none",
                marginBottom: "20px",

                padding: "20px",
                backgroundColor: "#B7B7B7",
              }}
              className="shadow-lg"
            >
              <Row>
                <Col lg={10} xs={10}>
                  <h5>React Developer</h5>
                  <p style={{ color: "grey" }}>Work From Home | Contract </p>
                </Col>
                <Col
                  lg={2}
                  xs={2}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={handleShow}
                >
                  <FiExternalLink />
                </Col>
              </Row>
            </Card>{" "}
          </Col>
          <Col md={12} lg={4} xs={10}>
            <Card
              className="career-sec2"
              style={{
                border: "none",
                padding: "20px",
                marginBottom: "20px",
                backgroundColor: "#B7B7B7",
              }}
              className="shadow-lg"
            >
              <Row>
                <Col lg={10} xs={10}>
                  <h5>Flutter Developer</h5>
                  <p style={{ color: "grey" }}>Work From Home | Contract </p>
                </Col>
                <Col
                  lg={2}
                  xs={2}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={handleShow}
                >
                  <FiExternalLink />
                </Col>
              </Row>
            </Card>{" "}
          </Col>
          <Col md={12} lg={4} xs={10}>
            <Card
              className="career-sec2"
              style={{
                border: "none",
                padding: "20px",
                marginBottom: "20px",
                backgroundColor: "#B7B7B7",
              }}
              className="shadow-lg"
            >
              <Row>
                <Col lg={10} xs={10}>
                  <h5>Web Designer</h5>
                  <p style={{ color: "grey" }}>Work From Home | Contract </p>
                </Col>
                <Col
                  lg={2}
                  xs={2}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={handleShow}
                >
                  <FiExternalLink />
                </Col>
              </Row>
            </Card>{" "}
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Send Us your Email Address</Modal.Title>
          </Modal.Header>
          <FormControl
            placeholder="abc@gmail.com"
            aria-label="Amount (to the nearest dollar)"
          />
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default Careers;
