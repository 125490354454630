import React, { useEffect } from "react";
import "./services.css";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import visas1 from "../Assets/Images/visas12.PNG";
import visaslogo from "../Assets/Images/logo.png";
import { Hashrouter as Router, Switch, Route, Link } from "react-router-dom";
import statotech from "../Assets/Images/statotech.png";
import stato from "../Assets/Images/stato.jpeg";
import dsvbg from "../Assets/Images/dsvbg.jpeg";
import dsv from "../Assets/Images/dsv.png";
import skphome from "../Assets/Images/skphome.PNG";
import logo1 from "../Assets/Images/logo1.png";
import udayyy from "../Assets/Images/udayyy.png";
import capture1 from "../Assets/Images/capture1.PNG";
import udaylog from "../Assets/Images/udaylog.png";
import StripesPink from "../Assets/Main/Hero/StripesPink.png";
import "./work.css";
import Workcard from "../components/Workcard";
import vitech from "../Assets/Images/vitech.jpg";
import "./test.css";
function Work() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div style={{ marginBottom: "150px" }}>
        <Row style={{ marginTop: "160px" }} className="justify-content-center">
          <Col md={12} lg={12} xs={12}>
            <h1 className="heading">
              Our Work is the front <br />
              face of our success
            </h1>
          </Col>
          <div className="overlay">
            <img
              height="180px"
              width="180px"
              style={{ opacity: "0.6" }}
              src={StripesPink}
            />
          </div>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              {" "}
              Let our work speak for us. We’re passionate about what we do!{" "}
              <br /> Our portfolio is a sheer presentation about industry
              experience, collaboration, expertise, and quality deliverables.
            </p>
          </Col>
        </Row>
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={12} xs={12}>
            <h1
              style={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "50px",
              }}
            >
              All Projects
            </h1>
          </Col>
        </Row>
        <Row
          className="justify-content-around"
          style={{ marginBottom: "40px", marginright: "30px" }}
        >
          <Col md={6} lg={5} xs={11}>
            <Link to="/project" style={{ textDecoration: "none" }}>
              <Workcard
                image1={statotech}
                image2={stato}
                title="StatoTech Solutions"
                button1="Business"
                button2="Design"
                button3="Development"
              />
            </Link>
          </Col>{" "}
          <Col md={6} lg={5} xs={11}>
            <Link to="/project1" style={{ textDecoration: "none" }}>
              <Workcard
                image1={vitech}
                image2={capture1}
                title="Vitech "
                button1="Business"
                button2="Design"
                button3="Development"
              />
            </Link>
          </Col>
          <Col md={6} lg={5} xs={11}>
            <Link to="/project2" style={{ textDecoration: "none" }}>
              <Workcard
                image1={dsvbg}
                image2={dsv}
                title="Direct Saree Veavers"
                text="  "
                button1="E commerce"
                button2="Design"
                button3="Development"
              />
            </Link>
          </Col>
          <Col md={6} lg={5} xs={11}>
            <Link to="/project3" style={{ textDecoration: "none" }}>
              <Workcard
                image1={udayyy}
                image2={udaylog}
                title="Uday Realty"
                text=" "
                button1="Real Estate"
                button2="Design"
                button3="Development"
              />
            </Link>
          </Col>
          <Col md={6} lg={5} xs={11}>
            <Link to="/project4" style={{ textDecoration: "none" }}>
              <Workcard
                image1={skphome}
                image2={logo1}
                title="SKP Advisory"
                text="  "
                button1="Advisory"
                button2="Design"
                button3="Development"
              />
            </Link>
          </Col>
          <Col md={6} lg={5} xs={11}>
            <Link to="/project5" style={{ textDecoration: "none" }}>
              <Workcard
                image1={visas1}
                image2={visaslogo}
                title="Visas2go"
                button1="Consultancy"
                button2="Design"
                button3="Development"
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Work;
